import React, { useContext } from "react";
import LeftImageCard from "../../Components/LeftImageCard/LeftImageCard";
// import HighlightText from "../../Components/HighlightText/HighlightText";
// import ImageCards from "../../Components/ImageCards/ImageCards";
import RightImageCard from "../../Components/RightImageCard/RightImageCard";
import TenutaBanner from "../../Components/TenutaBanner/TenutaBanner";
// import person from "../../assets/images/People.png";
import * as styles from "./Persone.module.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { graphql } from "gatsby";
import { SEO } from "../../Components/Seo/seo";
import { languageContext } from "../../Components/Header/Header";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

const Index = ({ data }) => {
  const llanguage = useContext(languageContext);
  const { language } = llanguage;
  const localizations =
    data?.allStrapiPersone?.edges[0]?.node?.localizations || {};
  const {
    ContentWithImage: localContentWithImage,
    ContentWithImage2: localContentWithImage2,
    HeaderContent: localHeaderContent,
  } = localizations?.data[0]?.attributes;
  const {
    // Text,
    OwnerMission,
    ContentWithImage,
    ContentWithImage2,
    HeaderContent
  } =
    language === "it"
      ? localizations?.data[0]?.attributes
      : data?.allStrapiPersone?.edges[0]?.node || {};

  const { 
    // Designation,
    //  Name
     } =
    language === "it"
      ? localizations?.data[0]?.attributes
      : data?.allStrapiPersone?.edges[0]?.node || {};

  return (
   <>
    <div className="pageWrapper">
      <Header />

      <TenutaBanner
        persone={true}
        bannerTitle={HeaderContent?.Title}
        bannerImg={
          language === "it"
            ? localHeaderContent?.Image?.data?.attributes?.url
            : HeaderContent?.Image?.url
        }
        vini={true}
      />

      {/* Quote Section  */}
      <div className={styles.quoteSection}>
      <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <h2 className="animate__animated animate__fadeInUp">{HeaderContent?.Description}</h2>
        </AnimationOnScroll>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <p className="animate__animated animate__fadeInUp">{OwnerMission}</p>
        </AnimationOnScroll>
      </div>

      <div className={styles.PersonLeftImg}>
        <LeftImageCard
          person={true}
          personImg={
            language === "it"
              ? localContentWithImage?.Image?.data?.attributes?.url
              : ContentWithImage?.Image?.url
          }
          paragraph_1={ContentWithImage?.ParaGraph1}
          paragraph_2={ContentWithImage?.ParaGraph2}
        />
      </div>

      {/* <HighlightText persone={true} Title={Text} /> */}

      {/* Persons  */}
      {/* <ImageCards description={Designation} title={Name} cardImg={person} /> */}

      <RightImageCard
        person={true}
        personImg={
          language === "it"
            ? localContentWithImage2?.Image?.data?.attributes?.url
            : ContentWithImage2?.Image?.url
        }
        paragraph_1={ContentWithImage2?.ParaGraph1}
        paragraph_2={ContentWithImage2?.ParaGraph2}
      />
    </div>
          <Footer />
   </>
  );
};

export default Index;

export const query = graphql`
  query MyQueryAndMyQuery {
    allStrapiPersone {
      edges {
        node {
          Text
          OwnerMission
          ContentWithImage2 {
            ParaGraph1
            ParaGraph2
            Image {
              url
            }
          }
          ContentWithImage {
            ParaGraph1
            ParaGraph2
            Image {
              url
            }
          }
          HeaderContent {
            Description
            Title
            Image {
              url
            }
          }
          MetaKeyword
          MetaDescription
          localizations {
            data {
              attributes {
                ContentWithImage2 {
                  ParaGraph1
                  ParaGraph2
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
                HeaderContent {
                  Description
                  Title
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
                OwnerMission
                Text
                MetaKeyword
                MetaDescription
                ContentWithImage {
                  ParaGraph1
                  ParaGraph2
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allStrapiTeam {
      edges {
        node {
          Designation
          Name
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const localizations =
    data?.allStrapiPersone?.edges[0]?.node?.localizations || {};
  const {MetaKeyword , MetaDescription } = localizations?.data[0]?.attributes || {};
  return (
    <SEO title={MetaKeyword} description={MetaDescription} />
  );
};
