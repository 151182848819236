import React from "react";
import * as styles from "./RightImageCard.module.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

const RightImageCard = ({
  personImg,
  paragraph_1,
  paragraph_2,
  title,
  terrior,
  tenuta,
  person,
}) => {
  return (
    <div className={`${styles.ctaSection} ${person && styles.personePage}`}>
      <div className={styles.ctaBoxWrap}>
        <div
          className={`${styles.ctaDescription} ${
            tenuta && styles.ctaDescription_2
          }`}
        >
           <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          {title ? <h1 className="animate__animated animate__fadeInUp">{title}</h1> : null}
          </AnimationOnScroll>
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          {paragraph_1 ? <p className="animate__animated animate__fadeInUp">{paragraph_1}</p> : null}
          </AnimationOnScroll>
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          {paragraph_2 ? <p className="animate__animated animate__fadeInUp">{paragraph_2}</p> : null}
          </AnimationOnScroll>
        </div>
        <div className={styles.ctaImage}>
          <img src={personImg} alt="icon" />
        </div>
      </div>
    </div>
  );
};

export default RightImageCard;
