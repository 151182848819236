import React from "react";
import * as styles from "./LeftImageCard.module.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

const LeftImageCard = ({
  personImg,
  paragraph_1,
  paragraph_2,
  title,
  terrior,
  person,
}) => {

  return (
    <div className={`${styles.ctaSection} ${terrior && styles.terriorPage} ${person && styles.personPage}`}>
      <div className={styles.ctaBoxWrap}>
        <div className={styles.ctaImage}>
          <img src={personImg} />
        </div>
        <div className={`${styles.ctaDescription} ${terrior && styles.ctaMap}`}>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          {title ? <h1 className="animate__animated animate__fadeInUp">{title}</h1> : null}
          </AnimationOnScroll>
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          {paragraph_1 ? <p className="animate__animated animate__fadeInUp">{paragraph_1}</p> : null}
          </AnimationOnScroll>
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          {paragraph_2 ? <p className="animate__animated animate__fadeInUp">{paragraph_2}</p> : null}
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};

export default LeftImageCard;